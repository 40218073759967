import React from "react"
import Resume from "../components/Resume"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import resumeObj from "../data/resumeData.json"

const NotFoundPage: React.FC<void> = () => (
  <Layout>
    <SEO title="Resume" />
    <Resume resumeData={resumeObj} />
  </Layout>
)

export default NotFoundPage
