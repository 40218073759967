// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

// helper functions
const reverseInPlace = (str: string): string =>
  str.split(",").reverse().toString()

const formatBirthDate = (date: string, locale: string, options: any): string =>
  new Date(date).toLocaleDateString(locale, options)

//----------------------------------------------------------------

// sub components

// detail box component

type DetailBoxProps = {
  title: string
  value: string
  className: string
}

const DetailBox: React.FC<DetailBoxProps> = ({ title, value, className }) => {
  return (
    <div className={className}>
      <p className="font-semibold text-blue-700 sm:text-sm md:text-lg">
        {title}
      </p>
      <p className="font-semibold sm:text-xs md:text-sm">{value}</p>
    </div>
  )
}

// experience box component
const ExperienceDot = styled.div`
  margin-left: -1.25rem;
`
type ExperienceType = {
  id?: string
  title: string
  company: string
  description?: string
  start?: number
  end?: number
}
interface ExperienceBoxProps extends ExperienceType {
  className: string
}
const ExperienceBox: React.FC<ExperienceBoxProps> = ({
  title,
  company,
  description,
  start,
  end,
  className,
}) => {
  return (
    <div className="relative">
      <div className={`${className} flex flex-col border-l-4  pl-2 `}>
        <div className="flex flex-row ">
          <ExperienceDot className="w-5 h-5 bg-blue-700 border-4 border-white rounded-full " />
          <p
            className={`${
              description ? "sm:w-3/4" : ""
            } pl-1 font-bold    text-blue-700 ExperienceTitle`}
          >
            {title}
          </p>
        </div>
        <p className="pl-1 text-sm font-bold ExperienceCompany ">{company}</p>
        {description && (
          <p className="pl-1 text-sm text-gray-800 ExperienceDescription">
            {description}
          </p>
        )}
      </div>
      {start && (
        <p className="absolute top-0 right-0 pt-1 text-xs font-semibold">{`${start} - ${
          end === new Date().getFullYear() ? "Now" : end
        }`}</p>
      )}
    </div>
  )
}

// skill box component
type SkillType = {
  name: string
  level: number
}
interface SkillBoxProps extends SkillType {
  className: string
}
const SkillBox: React.FC<SkillBoxProps> = ({ className, name, level }) => {
  return (
    <div className={`${className} relative pt-1`}>
      <div className="flex items-center justify-between mb-2">
        <div>
          <span className="inline-block px-2 py-1 text-xs font-semibold text-blue-700 capitalize bg-blue-200 rounded-full ">
            {name}
          </span>
        </div>
        <div className="text-right">
          <span className="inline-block text-xs font-semibold text-blue-700 ">
            {`${Math.round((level / 4) * 100)}%`}
          </span>
        </div>
      </div>
      <div className="flex h-2 mb-4 overflow-hidden text-xs bg-blue-200 rounded">
        <div
          style={{ width: `${Math.round((level / 4) * 100)}%` }}
          className="flex flex-col justify-center text-center text-white bg-blue-500 shadow-none whitespace-nowrap"
        ></div>
      </div>
    </div>
  )
}

//----------------------------------------------------------
const btnStyle = css`
  text-decoration: none;
  color: rgb(59, 130, 246);
  background: white;
  border: 2px solid rgb(59, 130, 246);
  font-weight: 600;
  font-size: 0.75rem;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  transition: all 0.4s;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 3rem;
  &:hover {
    color: white;
    background: rgb(59, 130, 246);
    opacity: 0.8;
    box-shadow: 0 14px 26px -12px rgba(131, 0, 255, 0.42),
      0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(131, 0, 255, 0.2);
  }
`
//backbtn components
const BackToHome = styled(Link)`
  padding: 5px 25px;
  ${btnStyle}
`
const PrintBtn = styled.button`
  padding: 5px 25px;
  ${btnStyle}
`
//----------------------------------------------------------
type PersonalDetailsType = {
  id: string
  title: string
  value: string
}

type EducationsType = {
  title: string
  company: string
}

type LanguagesType = {
  name: string
  level: number
  isNative: boolean
}

type PageContextProps = {
  resumeData: {
    firstName: string
    lastName: string
    personalDetails: {
      [key: string]: PersonalDetailsType
    }
    occupation: string
    experiences: ExperienceType[]
    educations: EducationsType[]
    skills: SkillType[]
    languages: LanguagesType[]
  }
}

// main component

const Component: React.FC<PageContextProps> = ({
  resumeData: {
    firstName,
    lastName,
    personalDetails,
    occupation,
    experiences,
    educations,
    skills,
    languages,
  },
}) => {
  const imageParsed = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "resumeAvatar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const birthDateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  }

  const formatedBirthDate = {
    id: "birthDate",
    title: "Birth Date",
    value: reverseInPlace(
      formatBirthDate(
        personalDetails.birthDate.value,
        "en-US",
        birthDateOptions
      )
    ),
  }
  const normalizedDetails = Object.values({
    ...personalDetails,
    birthDate: formatedBirthDate,
  })

  if (!imageParsed?.placeholderImage?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }
  return (
    <>
      <div className="container flex flex-wrap w-screen px-4 mx-auto text-black font-resumeFont md:flex-row sm:flex-col">
        <div
          id="resumeContainer"
          className="container flex flex-wrap w-full md:flex-row sm:flex-col"
        >
          <div id="profile" className="flex flex-row pb-2 mt-1 md:w-full">
            <Img
              className="border-4 border-blue-700 border-solid rounded-full sm:mx-3 md:mx-8 md:w-64 sm:w-32 "
              fluid={imageParsed.placeholderImage.childImageSharp.fluid}
            />
            <div className="flex flex-col justify-center capitalize md:mx-auto md:text-center align-center">
              <p className="font-bold text-blue-700 md:text-5xl sm:text-xl">
                {`${firstName} ${lastName}`}
              </p>
              <p className="font-semibold text-black md:text-4-xl sm:text-xs">
                {occupation}
              </p>
            </div>
          </div>
          <div id="contents" className="mt-1 md:w-1/4">
            <p className="font-semibold md:text-2xl sm:text-lg ">
              Personal Details:
            </p>

            <div className="flex md:justify-items-start md:flex-wrap sm:justify-center sm:flex-row md:flex-col ">
              {normalizedDetails.map(
                ({ id, title, value }: PersonalDetailsType) => (
                  <DetailBox
                    key={id}
                    className="px-1 text-black sm:mx-auto sm:text-center md:pl-8"
                    title={title}
                    value={value}
                  />
                )
              )}
            </div>
            <p className="pt-3 font-semibold text-black md:text-2xl sm:text-lg ">
              languages:
            </p>
            <div className="flex flex-row flex-wrap justify-items-start ">
              {languages.map(({ name, level, isNative }: LanguagesType) => (
                <SkillBox
                  name={`${name} ${isNative ? "(native)" : ""}`}
                  key={name}
                  className="sm:px-2 md:px-8 md:w-full sm:w-1/2 sm:text-center "
                  level={level}
                />
              ))}
            </div>
          </div>
          <div id="experiences" className="flex flex-col mt-1 md:w-3/4 ">
            <p className="pb-3 font-semibold md:text-2xl sm:text-lg">
              Experiences :
            </p>
            {experiences.map(
              ({
                id,
                title,
                company,
                description,
                start,
                end,
              }: ExperienceType) => (
                <ExperienceBox
                  key={id}
                  className="w-full pb-2"
                  title={title}
                  company={company}
                  description={description}
                  start={start}
                  end={end}
                />
              )
            )}
            <p className="pt-4 pb-1 font-semibold md:text-2xl sm:text-lg">
              Educations :
            </p>
            {educations.map(({ title, company }: EducationsType) => (
              <ExperienceBox
                key={title}
                className="w-full pb-2"
                title={title}
                company={company}
              />
            ))}
            <p className="pt-4 pb-1 font-semibold md:text-2xl sm:text-lg">
              Skills &amp; Tools :
            </p>
            <div className="flex flex-row flex-wrap">
              {skills.map(({ name, level }: SkillType) => (
                <SkillBox
                  key={name}
                  className="px-3 md:w-1/3 sm:w-full"
                  name={name}
                  level={level}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center mx-auto mt-4 md:w-1/2 sm:w-full print:hidden">
          <BackToHome className="md:w-1/3" to="/">
            Back To Home
          </BackToHome>
          <PrintBtn className="md:w-1/3" onClick={() => window.print()}>
            Print me!
          </PrintBtn>
        </div>
      </div>
    </>
  )
}

export default Component
